/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import Modal from "react-modal"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import { initPopup } from "typebot-js"
import { GatsbyImage } from "gatsby-plugin-image"

Modal.setAppElement(`#___gatsby`)

const IntroAlt = ({ title, subtitle, image, buttonLeft /*buttonRight*/ }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div className="intro-alt">
      <div className="shell">
        <div className="intro__inner">
          <div className="intro__content">
            <div className="intro__entry">
              {title && <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>}

              {subtitle && (
                <h3 dangerouslySetInnerHTML={{ __html: subtitle }}></h3>
              )}
            </div>

            <div className="intro__actions">
              {!isEmpty(buttonLeft) && (
                <button
                  className="btn btn--primary-alt"
                  onClick={() =>
                    initPopup({
                      publishId: "captaincyber-ask-a-demo",
                      delay: 300,
                      backgroundColor: "#ffffff",
                    })
                  }
                >
                  {buttonLeft.caption}
                </button>
              )}
              {/* {!isEmpty(buttonRight) && (
                <a
                  href={buttonRight.link}
                  className="btn btn--outline-secondary"
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {buttonRight.caption}
                </a>
              )} */}
            </div>
          </div>

          <div className="intro__aside">
            <GatsbyImage
              style={{ maxHeight: "500px" }}
              image={image.childImageSharp.gatsbyImageData}
              alt="Hero Image CaptainCyber"
              objectFit="contain"
              onClick={() => {
                setOpen(true)
              }}
            />
          </div>
        </div>
      </div>
      {typeof window !== "undefined" && (
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="1SXzGacceFE"
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  )
}

IntroAlt.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  buttonLeft: PropTypes.object,
  buttonRight: PropTypes.object,
}

IntroAlt.defaultProps = {
  title: "",
  subtitle: "",
  image: null,
  buttonLeft: {},
  buttonRight: {},
}

export default IntroAlt
