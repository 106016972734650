import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  IntroAlt,
  Benefits,
  Features,
  PartnerSlider,
} from "../components/landing"
import Outro from "../components/outro"
import Reviews from "../components/landing/reviews"

const Index = ({ data }) => {
  const {
    benefits,
    description,
    features,
    newintro,
    outro,
    partners_slider,
    title,
    reviews,
  } = data.mdx.frontmatter

  return (
    <Layout>
      <Seo title={title} description={description} />
      {newintro && <IntroAlt key="#newintro" {...newintro} />}
      {partners_slider && <PartnerSlider key="#slider" {...partners_slider} />}
      {benefits && <Benefits key="#benefits" {...benefits} />}
      {features && <Features key="#features" {...features} />}
      {reviews && <Reviews key="reviews" {...reviews} />}
      {outro && <Outro key="#outro" {...outro} />}
    </Layout>
  )
}

export default Index

export const query = graphql`
  query ($locale: String!) {
    mdx(
      fileAbsolutePath: { regex: "/static-pages/landing/" }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        reviews {
          title {
            blue
            red
          }
          items {
            job_title
            name
            text
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        outro {
          title
          buttonLeft {
            link
            caption
          }
        }
        newintro {
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          buttonLeft {
            link
            caption
          }
          buttonRight {
            link
            caption
          }
        }
        partners_slider {
          title
          slides {
            caption
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        benefits {
          title
          benefits {
            name
            icon {
              publicURL
            }
            description
          }
        }
        features {
          title
          slides {
            caption
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            title
            subfeatures {
              subfeature
            }
            verbatim
          }
        }
      }
    }
  }
`
