import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { initPopup } from "typebot-js"

const Outro = ({ title, buttonLeft, buttonRight }) => (
  <div className="section-callout">
    <div className="section__inner">
      <div className="shell">
        {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}

        <div className="section__actions">
          {!isEmpty(buttonLeft) && (
            <button
              className="btn btn--secondary"
              onClick={() =>
                initPopup({
                  publishId: "captaincyber-ask-a-demo",
                  delay: 300,
                  backgroundColor: "#ffffff",
                })
              }
            >
              {buttonLeft.caption}
            </button>
          )}
          {!isEmpty(buttonRight) && (
            <a
              href={buttonRight.link}
              className="btn btn--outline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {buttonRight.caption}
            </a>
          )}
        </div>
      </div>
    </div>
  </div>
)

Outro.propTypes = {
  title: PropTypes.string,
  buttonLeft: PropTypes.object,
  buttonRight: PropTypes.object,
}

Outro.defaultProps = {
  title: "",
  buttonLeft: {},
  buttonRight: {},
}

export default Outro
