import React from "react"
import PropTypes from "prop-types"
import { Stack, Box, Typography } from "@mui/material"

import SlickSlider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

const Reviews = ({ title, items }) => {
  return (
    <Stack
      component="section"
      bgcolor="#FBFBFF"
      width="100vw"
      position="relative"
      left="50%"
      right="50%"
      ml="-50vw"
      mr="-50vw"
    >
      <Box maxWidth="1200px" width="100%" mx="auto" px={2} pt={8} pb={10}>
        <Box
          maxWidth="780px"
          mx="auto"
          textAlign="center"
          mb={{ base: 4, md: 8 }}
        >
          <Typography
            color="primary"
            mb={2}
            fontSize={{ base: "34px", xl: "40px", lg: "38px" }}
            fontWeight="bold"
            lineHeight="1.2"
          >
            <Typography
              fontSize={{
                base: "34px",
                xl: "40px",
                lg: "38px",
              }}
              fontWeight="bold"
              color="secondary"
              lineHeight="1.2"
            >
              {title.red}
              <br />
            </Typography>
            {title.blue}
            <Typography
              component="span"
              fontSize={{
                base: "34px",
                xl: "40px",
                lg: "38px",
              }}
              fontWeight="bold"
              lineHeight="1.2"
              color="secondary"
            >
              !
            </Typography>
          </Typography>
        </Box>
        <Slider slides={items} />
      </Box>
    </Stack>
  )
}

Reviews.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Reviews

const Slider = ({ slides }) => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },

      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Box
      maxWidth="100vw"
      overflow="hidden"
      sx={{
        ".slick-track": {
          display: "flex!important",
          pb: 2,
        },
        ".slick-slide": {
          height: "auto!important",
        },
        ".slick-slide > *": {
          height: "100%!important",
        },
      }}
    >
      <SlickSlider {...settings}>
        {slides.map((slide, i) => (
          <ReviewCard key={`review_card_${i}`} {...slide} />
        ))}
      </SlickSlider>
    </Box>
  )
}

Slider.propTypes = {
  slides: PropTypes.array.isRequired,
}

const ReviewCard = ({ text, name, job_title, image }) => (
  <Stack
    p="1.3125rem 1.3125rem 1.3125rem"
    mx={{ base: 1, md: 2 }}
    my={1}
    boxShadow="0px 0px 10px rgba(0, 0, 101, 0.1)"
    bgcolor="#fff"
    borderRadius="14px"
    height="100%"
    justifyContent="space-between"
  >
    <QuoteIcon />
    <Stack flexGrow={1} justifyContent="center">
      <Typography color="#464646!important" lineHeight="1.4rem">
        {text}
      </Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={2} flexGrow={0} mt={1}>
      <Box
        flexShrink="0"
        width="45px"
        height="45px"
        bgcolor="#33333380"
        borderRadius="50%"
        border="2px solid #DB3433"
      >
        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={name} />
      </Box>
      <Box fontSize="0.875rem" color="#242424">
        <Typography fontWeight="bold" lineHeight="1.0625rem" mb="0.2rem">
          {name}
        </Typography>
        <Typography lineHeight="1.0625rem">{job_title}</Typography>
      </Box>
    </Stack>
  </Stack>
)

ReviewCard.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  job_title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

const QuoteIcon = () => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.24362 15.9191C3.99623 15.9191 2.94375 15.4903 2.08617 14.6327C1.22859 13.7361 0.799805 12.4693 0.799805 10.8321C0.799805 8.68813 1.34554 6.7196 2.437 4.92648C3.52846 3.09438 4.93177 1.65209 6.64693 0.599609L8.34259 1.47668C7.64094 2.10037 6.93928 2.99693 6.23763 4.16635C5.53597 5.33578 5.14617 6.58316 5.0682 7.90851C6.23763 7.98647 7.21215 8.37628 7.99176 9.07793C8.77138 9.77959 9.16119 10.6761 9.16119 11.7676C9.16119 13.015 8.77138 14.0285 7.99176 14.8081C7.25113 15.5487 6.33508 15.9191 5.24362 15.9191Z"
      fill="#000065"
    />
    <path
      d="M14.8822 15.9191C13.6348 15.9191 12.5824 15.4903 11.7248 14.6327C10.8672 13.7361 10.4384 12.4693 10.4384 10.8321C10.4384 8.68813 10.9842 6.7196 12.0756 4.92648C13.1671 3.09438 14.5704 1.65209 16.2855 0.599609L17.9812 1.47668C17.2796 2.10037 16.5779 2.99693 15.8762 4.16635C15.1746 5.33578 14.7848 6.58316 14.7068 7.90851C15.8762 7.98647 16.8508 8.37628 17.6304 9.07793C18.41 9.77959 18.7998 10.6761 18.7998 11.7676C18.7998 13.015 18.41 14.0285 17.6304 14.8081C16.8897 15.5487 15.9737 15.9191 14.8822 15.9191Z"
      fill="#000065"
    />
  </svg>
)
