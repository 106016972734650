import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import Slider from "react-slick"

const PartnersSlider = ({ title, slides }) => {
  const settings = {
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 7000,
    autoplaySpeed: 100,
    cssEase: "linear",
    slidesToShow: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 374,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <section id="partners" className="section-partners">
      <div className="shell">
        {title && (
          <header className="section__head">
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
          </header>
        )}
        {!isEmpty(slides) && (
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div key={index} className="slider">
                <div className="slide_item">
                  <img
                    src={
                      slide.image.childImageSharp.gatsbyImageData.images
                        .fallback.src
                    }
                    alt={slide.caption}
                  />
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </section>
  )
}

PartnersSlider.propTypes = {
  title: PropTypes.string,
  slides: PropTypes.array,
  link: PropTypes.object,
}

PartnersSlider.defaultProps = {
  title: "",
  link: {},
  slides: [],
}

export default PartnersSlider
