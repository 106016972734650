import React from "react"
import PropTypes from "prop-types"
import { has } from "lodash"

const Benefits = ({ title, button, benefits }) => (
  <section id="benefits" className="section-benefits">
    <div className="shell">
      <header className="section__head">
        {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
      </header>
      <div className="section__body">
        <div className="benefits">
          {benefits.map((benefit, index) => (
            <div key={index} className="benefit">
              {has(benefit, "icon") && (
                <div className="benefit__icon">
                  <i className="ico-svg-brain">
                    <img src={benefit.icon.publicURL} alt="icon" />
                  </i>
                </div>
              )}
              {has(benefit, "name") && (
                <div className="benefit__head">
                  <h4 dangerouslySetInnerHTML={{ __html: benefit.name }}></h4>
                </div>
              )}
              {has(benefit, "description") && (
                <div className="benefit__body">
                  <p
                    dangerouslySetInnerHTML={{ __html: benefit.description }}
                  ></p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)

Benefits.propTypes = {
  title: PropTypes.string,
  benefits: PropTypes.array,
}

Benefits.defaultProps = {
  title: "",
  benefits: [],
}

export default Benefits
