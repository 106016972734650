import React, { useState } from "react"
import PropTypes from "prop-types"
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import { isEmpty } from "lodash"
import logo from "../../../static/assets/check-icon.png"
import { GatsbyImage } from "gatsby-plugin-image"

const Features = ({ title, slides }) => {
  const [features, setfeatures] = useState({})
  const [currentfeaturesIndex, setCurrentfeaturesIndex] = useState(0)

  const settings = {
    arrows: false,
    dots: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    adaptiveHeight: true,
    beforeChange: (current, next) => setCurrentfeaturesIndex(next),
  }

  const goTo = index => {
    features.slickGoTo(index)
    setCurrentfeaturesIndex(index)
  }

  return (
    <section id="features" className="section-features">
      <div className="shell">
        {title && (
          <header className="section__head">
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
          </header>
        )}

        {!isEmpty(slides) && (
          <div className="section__body">
            <div className="features-tabbar">
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`features-tabbar-item ${
                    currentfeaturesIndex === index ? "current" : ""
                  }`}
                >
                  <button type="button" onClick={() => goTo(index)}>
                    {slide.caption}
                  </button>
                </div>
              ))}
            </div>

            <div className="features-view">
              <Slick ref={features => setfeatures(features)} {...settings}>
                {slides.map((slide, index) => (
                  <div key={index}>
                    <div className="features-view-container">
                      <div className="features-view-left">
                        <GatsbyImage
                          image={slide.image.childImageSharp.gatsbyImageData}
                          alt={`Slide Image ${index}`}
                        />
                      </div>
                      <div className="features-view-right">
                        <h3
                          dangerouslySetInnerHTML={{ __html: slide.title }}
                        ></h3>
                        {slide.subfeatures.map((subfeature, index) => (
                          <div key={index}>
                            <div className="features-view-paragraph">
                              <img src={logo} alt="Logo" />
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: subfeature.subfeature,
                                }}
                              ></p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </Slick>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

Features.propTypes = {
  title: PropTypes.string,
  slides: PropTypes.array,
}

Features.defaultProps = {
  title: "",
  slides: [],
}

export default Features
